<template>
        <div>
            <img src="@/assets/images/isFrom.png" class="img">

            <div class="text">
                <!-- <span class="title-son">{{ $store.state.user.version }}</span> -->
                <p class="title">正在进入云楚新能源商城...... </p>
                <p class="main">正在进入云楚新能源精选商城。因尚未在云楚新能源精选商城注册用户。我们将会为您在云楚新能源精选商城上注册会员。请确认此操作。</p>
            </div>

            <div class="btn">
                    <button class="default" @click="from">返回</button>
                    <button class="primary" @click="go">同意并前往</button>
            </div>
        </div>
</template>
<script>
import { setToken } from "@/utils/auth";

export default {
    data(){
 
    },
    methods:{
        from(){
        let url = location.href.split('?')[1]
        let from = url.split('&')[1].split('=')[1]
        window.history.back()
        if(from ==7){
            // window.location.href =`http://192.168.31.86:8083/#/home`;//测试

        }else if(from == 8){

        }else if (from == 9){

        }

        },


        go(){
             let url = location.href.split('?')[1]
        let mobile = url.split('&')[0].split('=')[1]
        let from = url.split('&')[1].split('=')[1]
       
        if(from == 9){
            localStorage.storeId = 16
        }else{
            localStorage.removeItem('storeId')
        }
        console.log(mobile,from);
        let user = {
            phone: mobile,
            loginType:from
        }
        this.$api.login.InternalLogin(user).then(res=>{
            console.log(res);
                    setToken(res.data.accessToken);
                  // 把token存入本地
                  localStorage.setItem("token", res.data.accessToken);
                  localStorage.setItem("customerLevel", res.data.level);
                  localStorage.setItem("customerId", res.data.id);
                  localStorage.setItem(
                    "personalNumber",
                    res.data.birthday
                  );
                  localStorage.setItem(
                    "customerName",
                    // res.returnValue.loginCustomer.name
                    res.data.nickname
                  );
                  localStorage.setItem(
                    "mobile",
                    // res.returnValue.loginCustomer.mobile
                    res.data.phone
                  );
                  localStorage.setItem("isNew", res.data.loginType);
                  // res.returnValue.isNew);
                  localStorage.setItem(
                    "isWomenFestival",
                    // res.returnValue.isWomenFestival
                    res.data.status
                  );
                  // 跳转拿openID页面
                    let wxClient = this.is_weixn();

                //    if(wxClient){
                //      if(!sessionStorage.openId){
                //         let appid = window.g.appid;

                //         let url = location.href.split('#/')[0] + "/#/getOpenIdFrom";
                //         // console.log(encodeURIComponent(url));
                //         url = encodeURIComponent(url);
                //         window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${url}&response_type=code&scope=snsapi_base&state=1&connect_redirect=1#wechat_redirect`;
                //       }
                //      }
                
                // 测试
                if(from == 9){
                  try {
                      this.$router.replace({ path: "/indexAlone" });
                  } catch (err) {
                    this.$router.replace({ path: "/indexAlone" });
                  }
                }else{
                     try {
                      this.$router.replace({ path: "/index" });
                  } catch (err) {
                    this.$router.replace({ path: "/index" });
                  }
                }
                 
        })
        },
          is_weixn() {
                  var ua = navigator.userAgent.toLowerCase();
                  if (ua.match(/MicroMessenger/i) == "micromessenger") {
                    return true;
                  } else {
                    return false;
                  }
                },
        },
    mounted(){
       
    },
 
}
</script>

<style lang='less' scoped>
    .img{
        position: relative;
        top: .8rem;
        left: 50%;
        transform: translateX(-50%);
        width: 5.2rem;
    }
    .text{
        margin-top: .8rem;
        width: 100%;
        text-align: center;
    }
    .title{
        font-size: .46rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #DF3232;
        .title-son{
            font-size: .38rem;
            font-family: PingFang SC;
            font-weight: bold;
            color: #DF3232;
        }
    }
    .main{
        text-align: left;
        margin-top: .4rem;

        padding: 0 .4rem;
        font-size: .3rem;
        font-family: PingFang SC;
        color: #aeaeae;
        line-height: 1.5;
    }
    .btn{
        position: fixed;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: space-around;
        width: 100%;
        padding: .2rem 0;
        background: #fff;

        // box-shadow: 0px 0px .06rem 0px rgba(7,63,53,0.1000);
        .default{
            width: 44vw;
            height: .9rem;
            border-radius: .1rem;
             background: #fff;
            border-radius: .1rem;
            font-size: .36rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #7e7e7e;
            border: 1px solid #e1e1e1;

        }
        .primary{
            width: 42vw;
            height: .9rem;
            background: linear-gradient(0deg, #EE3A3A, #F46161);
            border-radius: .1rem;
            font-size: .36rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;

        }
        
    }
</style>